'use client'

import DisablePrefetchLink from '@component/disablePrefetchLink'
import classNames from 'classnames'
import { concat, isFunction, map, range } from 'lodash-es'
import { useEffect, useMemo, useState } from 'react'
import { buildUrl } from '@plugin/http/lib/utils'
import usePreload from '@hooks/usePreload'

// 快进
const FORWARD = {
  prev: 'prev',
  next: 'next'
}

export default function Pagination({
  pageIndex,
  pageSize,
  totals,
  onChange,
  pageNum = 3,
  url,
  target,
  right = false,
  center = false,
  preload = false,
  className
}) {

  // 当前index
  const [pageIdx, setPageIdx] = useState(pageIndex)
  // 最大页码，是否第一页，是否最后一页
  const [maxPage, isFirstPage, isLastPage] = useMemo(() => {
    let max = Math.ceil(totals / pageSize)
    let isFirst = pageIdx === 1
    let isLast = pageIdx === max
    return [
      max,
      isFirst,
      isLast
    ]
  }, [pageIdx, pageSize, totals])
  // 需要渲染的页码
  const pages = useMemo(() => {
    let res = []
    let half = (pageNum - 1) / 2
    // 小数量页面
    if (maxPage < pageNum + 4) {
      if (maxPage > 2) res = range(2, maxPage)
    }
    // 前几页
    else if (pageIdx <= pageNum) {
      res = concat(range(2, pageNum + 3), FORWARD.next)
    }
    // 后几页
    else if (pageIdx + pageNum > maxPage) {
      res = concat(FORWARD.prev, range(maxPage - pageNum - 1, maxPage))
    }
    // 中间页
    else {
      res = concat(FORWARD.prev, range(pageIdx - half, pageIdx + half + 1), FORWARD.next)
    }
    return res
  }, [maxPage, pageIdx, pageNum])

  useEffect(() => {
    setPageIdx(pageIndex)
  }, [pageIndex, setPageIdx])

  function prevHandle() {
    const n = Math.max(pageIdx - 1, 1)
    setPageIdx(n)
    isFunction(onChange) && onChange(n)
  }
  function nextHandle() {
    const n = Math.min(pageIdx + 1, maxPage)
    setPageIdx(n)
    isFunction(onChange) && onChange(n)
  }
  function clickHandle(idx) {
    return event => {
      event.preventDefault()
      let n = idx
      if (idx === FORWARD.prev) {
        n = Math.max(pageIdx - pageNum, 1)
      } else if (idx === FORWARD.next) {
        n = Math.min(pageIdx + pageNum, maxPage)
      }
      setPageIdx(n)
      isFunction(onChange) && onChange(n)
    }
  }
  function getUrl(idx) {
    return buildUrl(url, { page: idx })
  }

  return (
    <>
      <div className={classNames('flex items-center flex-wrap space-x-10', { 'justify-end': right, 'justify-center': center }, className)}>
        <PageButton disabled={isFirstPage} onClick={prevHandle}>
          <i className='icon icon-arrow-left'></i>
        </PageButton>
        <PageButton active={isFirstPage} url={getUrl(1)} target={target} onClick={clickHandle(1)} preload={preload}>1</PageButton>
        {
          map(pages, item => (
            <PageButton
              active={pageIdx === item}
              url={[FORWARD.prev, FORWARD.next].includes(item) ? null : getUrl(item)}
              target={target}
              onClick={clickHandle(item)}
              key={item}
              preload={preload}>{[FORWARD.prev, FORWARD.next].includes(item) ? '···' : item}</PageButton>
          ))
        }
        {
          maxPage > 1 && <PageButton active={isLastPage} url={getUrl(maxPage)} target={target} onClick={clickHandle(maxPage)} preload={preload}>{maxPage}</PageButton>
        }
        <PageButton disabled={isLastPage} onClick={nextHandle}>
          <i className='icon icon-arrow-right'></i>
        </PageButton>
      </div>
      {
        url && (
          <div className='hidden'>
            {/* each page */}
            {
              map(range(1, maxPage + 1), item => (
                <DisablePrefetchLink href={getUrl(item)} target={target} key={item}>page index {item}</DisablePrefetchLink>
              ))
            }
            {/* pre page */}
            {
              pageIdx > 1 && (
                <link rel='prev' href={getUrl(pageIdx - 1)}></link>
              )
            }
            {/* next page */}
            {
              pageIdx < maxPage && (
                <link rel='next' href={getUrl(pageIdx + 1)}></link>
              )
            }
          </div>
        )
      }
    </>
  )
}

function PageButton({
  children,
  url,
  target,
  active = false,
  disabled = false,
  preload = false,
  onClick
}) {

  usePreload(url, 'document', preload)
  const combineClass = classNames(
    'w-30 h-30 border flex items-center justify-center border-neutral border-solid text-neutral-4 text-sm',
    { 'cursor-not-allowed': disabled, 'hover:text-primary hover:bg-primary-light': !disabled },
    { '!bg-primary !text-white border-primary': active }
  )

  return (
    url
      ? <DisablePrefetchLink disabled={disabled} onClick={onClick} className={combineClass} href={url} target={target} data-noprogress>{children}</DisablePrefetchLink>
      : (
        <button type='button' disabled={disabled} onClick={onClick} className={combineClass} aria-label='navigation'>{children}</button>
      )
  )
}