import { isEqual, map } from 'lodash-es'
import { createContext, useCallback, useContext, useState } from 'react'
import { useBoolean, useList } from 'react-use'

export const LayoutContext = createContext()
export default function useLayoutContext() {
  return useContext(LayoutContext)
}

export function useLayoutState() {

  const [suburbs, { set: setSuburbs }] = useList([])
  const [surrounding, toggleSurrounding] = useBoolean(false)

  const [displayFilter, toggleFilter] = useBoolean(false)
  const showFilter = useCallback(() => toggleFilter(true), [toggleFilter])
  const hideFilter = useCallback(() => toggleFilter(false), [toggleFilter])

  const changeSuburbs = useCallback(data => {
    const dataIds = map(data, item => item.id)
    const suburbIds = map(suburbs, item => item.id)
    if (!isEqual(dataIds, suburbIds)) {
      setSuburbs(data)
    }
  }, [setSuburbs, suburbs])

  return {
    displayFilter,
    suburbs,
    surrounding,
    showFilter,
    hideFilter,
    changeSuburbs,
    toggleSurrounding,
  }
}